import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./error-boundary";
import { lazy } from "react";
const root = ReactDOM.createRoot(document.getElementById("root"));
const LazyApp = lazy(() => import("./App"));

root.render(
    <ErrorBoundary>
      <LazyApp />
    </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
