import { Component } from "react";
import { Button } from "reactstrap";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      chunkLoadError: false,
    };
  }
  componentDidMount() {}

  componentDidCatch(error, info) {
    this.setState({ error, info, hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? (
      <div className="misc-wrapper flex-column">
        <div className="misc-inner p-2 p-sm-3 text-center">
          <Button
            onClick={() => {
              window.location.reload(true);
            }}
            className="btn-sm-block mt-75 btn-primary"
          >
            Try Again !
          </Button>
        </div>
      </div>
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
